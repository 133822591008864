const page3Info = [
  {
    title: `1. <span class="ai-font-color">Build</span> your <span class="ai-font-color">personal</span> AIGC tools`,
    cards: [
      {
        img: 'icon1.svg',
        title: 'Input Information',
        desc: 'Boosted by ChatGPT, we can give users the best fit AIGC templates for their demands.',
      },
      {
        img: 'icon2.svg',
        title: 'Drag to Build',
        desc: 'Import the data that the you wants to customize, and then train the model.',
      },
      {
        img: 'icon3.svg',
        title: 'Publish to Earn',
        desc: 'After the training model, you can get a cloud native application, and empower your product quickly.',
      },
    ],
  },
  {
    title:
      '2. <span class="ai-font-color">Find</span> any AIGC tools to <span class="ai-font-color">create</span>',
    cards: [
      {
        img: 'icon4.svg',
        title: 'Come Up With Ideas',
        desc: '',
      },
      {
        img: 'icon5.svg',
        title: 'Use customized AIGC tools to create',
        desc: '',
      },
      {
        img: 'icon6.svg',
        title: 'Publish to Earn',
        desc: '',
      },
    ],
  },
  {
    title: '3. <span class="ai-font-color">Social</span> with Others',
    cards: [
      {
        img: 'icon7.svg',
        title: 'Publish your Creations',
        desc: '',
      },
      {
        img: 'icon8.svg',
        title: 'Comment/Like Others Creations',
        desc: '',
      },
      {
        img: 'icon9.svg',
        title: 'Be Friend with Others',
        desc: '',
      },
    ],
  },
];

export default page3Info;
