<template lang="">
  <div class="text-white bg-slate-700 rounded pt-2">
    <div
      class="flex justify-between items-start border-b-[0.5px] px-6 pt-3 pb-1"
    >
      <h1 class="2xl:text-2xl text-white text-lg">Join the Waitlist</h1>
      <button
        @click="close"
        class="text-gray-300 px-2 py-[1px] border-[0.5px] rounded-md text-sm active:scale-95 flex flex-row gap-2"
      >
        <span class="xl:block hidden">Close</span> &Chi;
      </button>
    </div>
    <main class="px-5 py-3 text-gray-300 mt-2">
      <form class="flex flex-col gap-x-6 gap-y-5">
        <div class="flex xl:flex-row flex-col w-full">
          <div class="flex flex-col xl:w-[48%] w-[100%]">
            <label for="first name">First Name</label>
            <input
              type="text"
              name="first name"
              id="first name"
              placeholder="Enter your first name"
              class="border-[1px] rounded-md px-2 py-1 bg-slate-600 text-[#4ff99e]"
              v-model="firstName"
            />
          </div>
          <div class="flex flex-col xl:w-[48%] w-[100%] xl:ml-[4%]">
            <label for="last name">Last Name</label>
            <input
              type="text"
              name="last name"
              id="last name"
              placeholder="Enter your last name"
              class="border-[1px] rounded-md px-2 py-1 bg-slate-600 text-[#4ff99e]"
              v-model="lastName"
            />
          </div>
        </div>
        <div class="flex flex-col">
          <label for="email">Email<span class="text-[#4ff99e]">﹡</span></label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            class="border-[1px] rounded-md px-2 py-1 bg-slate-600 text-[#4ff99e]"
            v-model="email"
            required
          />
        </div>
        <div class="h-6">
          <p v-if="errorMessage" class="text-red-400">{{ errorMessage }}</p>
          <p v-if="successMessage" class="text-green-300">
            {{ successMessage }}
          </p>
        </div>
        <button
          v-if="!successMessage"
          type="submit"
          @click.prevent="handleSubmitDebounced"
          class="bg-slate-800 text-white px-2 py-2 rounded-md active:scale-95 border-[1px] mb-4"
        >
          Submit
        </button>
      </form>
    </main>
  </div>
</template>
<script>
  import { ref } from 'vue';
  import { collection, addDoc } from 'firebase/firestore/lite';
  import { db } from '../main';
  import _ from 'lodash';

  export default {
    props: {
      closeForm: {
        type: Function,
        required: true,
      },
    },
    methods: {
      close() {
        this.closeForm();
      },
    },
    setup() {
      const firstName = ref('');
      const lastName = ref('');
      const email = ref('');
      const successMessage = ref('');
      const errorMessage = ref('');
      const isEmailValid = () => {
        const emailRegex = /^\S+@\S+$/;
        return emailRegex.test(email.value);
      };

      // 处理表单提交
      const handleSubmit = async () => {
        if (!isEmailValid()) {
          errorMessage.value = 'Please enter a valid email address.';
          return;
        } else {
          const dataRef = collection(db, 'users');
          const data = {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            sendTime: String(new Date().getTime()),
          };
          try {
            const docRef = await addDoc(dataRef, data);
            console.log('Document written with ID: ', docRef.id);
            successMessage.value = 'Thank you for your submission!';
            errorMessage.value = '';
          } catch (e) {
            console.error('Error adding document: ', e);
            errorMessage.value =
              'Something went wrong, please try again later.';
            successMessage.value = '';
          } finally {
            firstName.value = '';
            lastName.value = '';
            email.value = '';
          }
        }
      };
      const handleSubmitDebounced = _.debounce(handleSubmit, 1000, {
        leading: true, // 允许第一个请求立即发送
        trailing: false, // 防止最后一个请求再次触发
      });
      return {
        firstName,
        lastName,
        email,
        successMessage,
        errorMessage,
        handleSubmitDebounced,
      };
    },
  };
</script>
<style lang=""></style>
