<template>
  <div class="mt-[100px] w-full flex flex-col justify-center items-center">
    <h1
      class="font-medium text-[40px] leading-[40px] mb-[40px] text-[#D8E0E3]"
      v-html="title"
    />
    <!-- card -->
    <div class="flex flex-row gap-[20px]">
      <div
        v-for="card in cards"
        :key="card.cardTitle"
        class="w-[380px] h-[290px] rounded-[16px] border-[#D8E0E3] border-[1px] flex flex-col items-center"
      >
        <div class="h-[140px] flex justify-center items-center">
          <img
            :src="require(`../assets/img/pg3-icon/${card.img}`)"
            :alt="card.img"
            class="w-[100px]"
          />
        </div>
        <h2 class="w-[310px] text-[28px] text-center text-white leading-[41px]">
          {{ card.title }}
        </h2>
        <p
          class="w-[332px] text-[14px] leading-[24px] text-center text-[#898CA9]"
        >
          {{ card.desc }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'page3-item',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    computed: {
      title() {
        return this.item.title;
      },
      cards() {
        return this.item.cards;
      },
    },
  };
</script>
<style lang=""></style>
