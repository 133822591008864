const idCards = [
  {
    avatar: 'tim.webp',
    position: 'CEO',
    name: 'Tim Liao',
  },
  {
    avatar: 'victorLi.webp',
    position: 'COO',
    name: 'Victor Li',
  },
  {
    avatar: 'wang.webp',
    position: 'CFO',
    name: 'Chris Wang',
  },
  {
    avatar: 'starZhou.webp',
    position: 'CDO',
    name: 'Star Zhou',
  },
  {
    avatar: 'david.webp',
    position: 'AI Dev',
    name: 'David',
  },
  {
    avatar: 'jake.webp',
    position: 'AI Dev',
    name: 'Jake Sun',
  },
  {
    avatar: 'jody.webp',
    position: 'Web3 & Fullstack Dev',
    name: 'Jody Zhou',
  },
  {
    avatar: 'misty.webp',
    position: 'UI Designer',
    name: 'misty',
  },
  {
    avatar: 'fangjingjing.webp',
    position: '3D Artist',
    name: 'Jingjing Fang',
  },
];

export default idCards;
