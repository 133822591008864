<template>
  <div
    class="w-[238px] h-[246px] border-[1px] border-[#D8E0E3] rounded-[16px] flex-col flex justify-center items-center"
  >
    <img
      v-lazy="{
        src: require(`../assets/img/avatar/${card.avatar}`),
        loading: require('../assets/img/loading.gif'),
        error: 'your error image url',
      }"
      :alt="`avatar ${card.name}`"
      class="w-[108px] rounded-full"
    />
    <div class="text-[16px] text-[#BCBCBC] mt-[26px]">{{ card.position }}</div>
    <div class="text-[24px] text-[white]">{{ card.name }}</div>
  </div>
</template>
<script>
  export default {
    name: 'id-card',
    props: {
      card: Object,
    },
  };
</script>
<style lang=""></style>
