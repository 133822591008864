<template>
  <div class="font-ai w-full selection:bg-slate-700 selection:text-[#4ff99e]">
    <!-- page1 -->
    <div class="pt-[26px] relative">
      <Navbar :showForm="showForm" />

      <div
        class="flex justify-center items-center fixed top-0 left-0 w-full h-full z-[999] backdrop-blur-md"
        v-if="isShowForm"
      >
        <div class="fixed z-20 w-[26%] h-1/3 top-44">
          <EmailForm :closeForm="closeForm" />
        </div>
      </div>
      <div class="mx-[144px] mt-[107px]">
        <!-- title -->
        <div
          class="flex flex-col gap-[20px] font-medium text-[120px] leading-[120px]"
        >
          <h1 class="m-0 text-white">AIGC</h1>
          <h1 class="m-0 text-white">for</h1>
          <h1 class="ai-font-color m-0">EVERYONE</h1>
        </div>
        <!-- desc -->
        <p
          class="max-w-[748px] text-[24px] font-medium leading-[36px] mt-[61px] text-white"
        >
          Flash<span class="ai-font-color">AI</span> is a Web3 AIGC community
          based on the <span class="ai-font-color">arbitrium</span> to help you
          quickly have your <span class="ai-font-color">own</span> AIGC tools,
          or you can <span class="ai-font-color">find</span> any AIGC tools you
          want here. Turn your ideas and creations into your income.
        </p>
        <p></p>

        <div
          class="flex flex-row gap-[20px] text-[32px] leading-[32px] items-center my-[30px] text-white"
        >
          <span>Create/Use tools</span>
          <img src="../assets/img/Vector.svg" alt="vector" />

          <span>Upload to Community</span>
          <img src="../assets/img/Vector.svg" alt="vector" />
          <span>Earn</span>
        </div>

        <div
          class="ai-btn w-[190px] px-2 text-center py-2 text-white rounded-lg active:scale-95 text-[16px] font-medium cursor-pointer"
          @click="showForm"
        >
          Join in Waitlist
        </div>
      </div>
    </div>

    <!-- page2 -->
    <div class="pt-[130px] mt-[100px] mx-[193px] mb-[182px]">
      <h1 class="text-white text-[80px] leading-[80px] font-medium text-center">
        Flash<span class="ai-font-color">AI</span> Open<span
          class="ai-font-color"
          >edition</span
        >&nbsp;
        <span class="ai-font-color">NFT</span>
      </h1>

      <!-- img -->
      <div
        class="flex flex-row justify-center items-center mt-[100px] flex-wrap gap-x-8 gap-y-24"
      >
        <img
          v-for="item in [
            'cloud',
            'light',
            'fire',
            'ice',
            'water',
            'windy',
            'undead',
          ]"
          v-lazy="{
            src: require(`../assets/img/sleep/${item}.webp`),
            loading: require('../assets/img/loading.gif'),
            error: 'your error image url',
          }"
          :alt="`${item}`"
          :key="item"
          class="w-80"
        />
      </div>
    </div>

    <!-- page3 -->
    <div class="w-full flex flex-col justify-center items-center">
      <h1
        class="font-medium text-[60px] leading-[60px] text-[#D8E0E3] text-center"
      >
        What You Can Do in FlashAI?
      </h1>
      <!-- desc -->
      <Page3Item
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        class="w-full"
      />
    </div>

    <!-- page4 -->
    <div
      class="flex flex-row mt-[235px] gap-[76px] w-full justify-center items-center"
    >
      <!-- left -->
      <div
        class="text-[72px] leading-[80px] font-medium max-w-[324px] text-[#D8E0E3]"
      >
        Join in Flash<span class="ai-font-color">AI</span> Now
      </div>
      <!-- right -->
      <div class="flex flex-row gap-[20px]">
        <div>
          <div
            class="w-[380px] h-[553px] rounded-[16px] border-[1px] flex flex-col justify-between border-[#D8E0E3]"
          >
            <h1
              class="ai-font-color text-[48px] font-medium h-[160px] flex items-center justify-center border-b-2"
            >
              Individuals
            </h1>
            <div
              class="flex flex-col items-center flex-1 justify-between py-[40px]"
            >
              <ul>
                <li
                  class="flex flex-row gap-[12px] text-[#D8E0E3] items-center text-[20px]"
                >
                  <img src="../assets/img/right-tri.svg" alt="right-tri" />Use
                  No-Code Tools
                </li>

                <li
                  class="flex flex-row gap-[12px] text-[#D8E0E3] items-center text-[20px]"
                >
                  <img
                    src="../assets/img/right-tri.svg"
                    alt="right-tri"
                  />Publish to Community
                </li>
                <li
                  class="flex flex-row gap-[12px] text-[#D8E0E3] items-center text-[20px]"
                >
                  <img src="../assets/img/right-tri.svg" alt="right-tri" />Like,
                  Comment, Retweet
                </li>
              </ul>
              <div
                class="w-[300px] h-[64px] ai-btn text-[32px] font-medium flex justify-center items-center rounded-[12px]"
              >
                Free Mint
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div>
          <div
            class="w-[380px] h-[553px] rounded-[16px] border-[1px] border-[#D8E0E3] flex flex-col justify-between"
          >
            <h1
              class="ai-font-color text-[48px] font-medium h-[160px] flex items-center justify-center border-b-2"
            >
              Enterprise
            </h1>
            <div
              class="flex flex-col items-center flex-1 justify-between py-[40px]"
            >
              <ul>
                <li
                  class="flex flex-row gap-[12px] text-[#D8E0E3] items-center text-[20px]"
                >
                  <img src="../assets/img/right-tri.svg" alt="right-tri" />
                  ALL for Individuals
                </li>

                <li
                  class="flex flex-row gap-[12px] text-[#D8E0E3] items-center text-[20px]"
                >
                  <img src="../assets/img/right-tri.svg" alt="right-tri" />
                  Private Cloud
                </li>
                <li
                  class="flex flex-row gap-[12px] text-[#D8E0E3] items-center text-[20px]"
                >
                  <img src="../assets/img/right-tri.svg" alt="right-tri" />
                  Charges for Individuals
                </li>
              </ul>
              <div
                class="w-[300px] h-[64px] ai-btn text-[32px] font-medium flex justify-center items-center rounded-[12px]"
              >
                Mint
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>

    <!-- page5 -->
    <div class="mt-[236px] m-auto flex-col w-[1160px] flex gap-[20px]">
      <h1 class="ai-font-color text-[64px] self-start mb-[32px]">TimeLine</h1>
      <!-- line1 -->
      <div class="relative">
        <div class="flex flex-row">
          <div class="flex flex-row flex-1">
            <h1 class="text-[56px] text-[#D8E0E3]">2023</h1>
            <h2 class="text-[36px] text-[#D8E0E3] translate-y-6">Q1</h2>
          </div>
          <div class="flex flex-row flex-1">
            <h1 class="text-[56px] text-[#D8E0E3]">2023</h1>
            <h2 class="text-[36px] text-[#D8E0E3] translate-y-6">Q2</h2>
          </div>
        </div>
        <img
          src="../assets/img/line.svg"
          alt="timeline"
          class="w-full absolute top-[70px] -left-6 select-none"
        />
        <!-- desc -->
        <div class="flex flex-row items-start">
          <ul class="text-[16px] flex-1">
            <li class="text-[#D8E0E3]">Setup a team</li>
            <li class="text-[#D8E0E3]">Develop MVP</li>
            <li class="text-[#D8E0E3]">Promote NFT</li>
          </ul>
          <ul class="text-[16px] flex-1">
            <li class="text-[#D8E0E3]">Acquire users for Openedition</li>
            <li class="text-[#D8E0E3]">
              Research and develop community + no-code tools
            </li>
            <li class="text-[#D8E0E3]">Release beta version</li>
            <li class="text-[#D8E0E3]">Update community + no-code tools</li>
            <li class="text-[#D8E0E3]">Release public beta version</li>
          </ul>
        </div>
      </div>
      <!-- line2 -->
      <div class="relative">
        <div class="flex flex-row">
          <div class="flex flex-row flex-1">
            <h1 class="text-[56px] text-[#D8E0E3]">2023</h1>
            <h2 class="text-[36px] text-[#D8E0E3] translate-y-6">Q3</h2>
          </div>
          <div class="flex flex-row flex-1">
            <h1 class="text-[56px] text-[#D8E0E3]">2023</h1>
            <h2 class="text-[36px] text-[#D8E0E3] translate-y-6">Q4</h2>
          </div>
        </div>
        <img
          src="../assets/img/line.svg"
          alt="timeline"
          class="w-full absolute top-[70px] -left-6 select-none"
        />
        <!-- desc -->
        <div class="flex flex-row items-start">
          <ul class="text-[16px] flex-1">
            <li class="text-[#D8E0E3]">Upgrade team Develop</li>
            <li class="text-[#D8E0E3]">recommendation algorithms Release</li>
            <li class="text-[#D8E0E3]">official version</li>
          </ul>
          <ul class="text-[16px] flex-1">
            <li class="text-[#D8E0E3]">1w+ users Optimize community</li>
            <li class="text-[#D8E0E3]">incentive system Launch</li>
            <li class="text-[#D8E0E3]">
              recommendation algorithm on users profile
            </li>
          </ul>
        </div>
      </div>
      <!-- line3 -->
      <div class="relative">
        <div class="flex flex-row">
          <div class="flex flex-row flex-1">
            <h1 class="text-[56px] text-[#D8E0E3]">2024</h1>
          </div>
          <div class="flex flex-row flex-1">
            <h1 class="text-[56px] text-[#D8E0E3]">2025</h1>
          </div>
          <div class="flex flex-row flex-1">
            <h1 class="text-[56px] text-[#D8E0E3]">2026</h1>
          </div>
        </div>
        <img
          src="../assets/img/line.svg"
          alt="timeline"
          class="w-full absolute top-[70px] -left-6 select-none"
        />
        <!-- desc -->
        <div class="flex flex-row items-start">
          <ul class="text-[16px] flex-1">
            <li class="text-[#D8E0E3]">Develop AIGC algorithm</li>
            <li class="text-[#D8E0E3]">
              Enrich community activities 10k+ users
            </li>
            <li class="text-[#D8E0E3]">Collaborate with enterprise</li>
          </ul>
          <ul class="text-[16px] flex-1">
            <li class="text-[#D8E0E3]">Organize offline community</li>
            <li class="text-[#D8E0E3]">Expand global business 1M+ users</li>
            <li class="text-[#D8E0E3]">
              Collaborate with high-quality data users
            </li>
          </ul>
          <ul class="text-[16px] flex-1">
            <li class="text-[#D8E0E3]">Develop metaverse</li>
            <li class="text-[#D8E0E3]">
              Enrich virtual worlds with AI-generated content
            </li>
            <li class="text-[#D8E0E3]">10M+ users</li>
            <li class="text-[#D8E0E3]">Expand ecosystem</li>
            <li class="text-[#D8E0E3]">Develop virtual world</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- page6 -->
    <div class="w-full flex-col justify-center items-center flex">
      <h1 class="text-[white] text-[76px] mt-[139px] w-full text-center">
        Our <span class="ai-font-color">Team</span>
      </h1>
      <div
        class="grid grid-cols-4 gap-[50px] max-w-[1160px] content-center justify-center"
      >
        <IDCard v-for="card in idCards" :key="card.name" :card="card" />
      </div>
    </div>

    <!-- footer -->
    <footer
      class="flex flex-row justify-between mt-[121px] w-[1440px] m-auto pb-4"
    >
      <div class="text-[#D8E0E3] text-[18px]">
        Copyright 2023 <span class="ai-font-color">FlashAI</span>
      </div>
      <ul class="text-[14px] flex flex-row gap-[10px]">
        <li class="text-white">Contacts</li>
        <li class="text-white">+1 8058699003</li>
        <li class="text-white">flashai@flashai.org</li>
      </ul>
    </footer>
  </div>
</template>

<script>
  import Navbar from '@/components/Navbar.vue';
  import Page3Item from '@/components/Page3Item.vue';
  import IDCard from '@/components/IDCard.vue';
  import page3Info from '@/constant/page3Info';
  import idCards from '@/constant/idCardInfo';
  import EmailForm from '@/components/EmailForm.vue';

  export default {
    components: { Navbar, Page3Item, IDCard, EmailForm },
    name: 'home-page',
    data() {
      return {
        items: page3Info,
        idCards: idCards,
        isShowForm: false,
      };
    },
    methods: {
      showForm() {
        this.isShowForm = true;
      },
      closeForm() {
        this.isShowForm = false;
      },
    },
  };
</script>

<style scoped></style>
