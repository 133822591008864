<template>
  <div
    class="h-[50px] flex flex-row place-content-between items-center mx-[32px]"
  >
    <div
      class="ai-font-color lg:text-[36px] lg:leading-[36px] font-medium flex flex-row gap-2"
    >
      <img src="../assets/img/logo.png" alt="logo" class="w-20" />
      FlashAI
    </div>
    <ul
      class="flex flex-row gap-[51px] lg:text-[32px] lg:leading-[24px] justify-center items-center m-0"
    >
      <li class="text-white opacity-50 cursor-pointer">HOME</li>
      <li class="text-white opacity-50 cursor-pointer">TOOL</li>
      <li class="text-white opacity-50 cursor-pointer">COMMUNITY</li>
      <div
        class="lg:w-[260px] lg:h-[48px] rounded-[12px] text-[24px] flex justify-center items-center ai-btn"
        @click="handleClick"
      >
        Join in Waitlist
      </div>
    </ul>
  </div>
</template>
<script>
  export default {
    props: {
      showForm: {
        type: Function,
        default: () => {},
      },
    },
    name: 'home-navbar',
    setup(props) {
      const handleClick = () => {
        props.showForm();
      };
      return {
        handleClick,
      };
    },
  };
</script>
<style lang=""></style>
