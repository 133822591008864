import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/views/Home.vue';

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
});

export default router;
