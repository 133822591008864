import { createApp } from 'vue';
import App from './App.vue';
import antD from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from './router/index';
import 'animate.css';
import 'animate.css/animate.compat.css';
import VueLazyLoad from 'vue3-lazyload';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';

const app = createApp(App);

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
app.use(db);

app.use(VueLazyLoad, {
  // options...
});
app.use(antD);
app.use(router);
app.mount('#app');
