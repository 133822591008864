<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
  };
</script>

<style>
  @import '@/assets/style.css';
  @tailwind base;

  @layer base {
    h1 {
      @apply m-0 mb-[32px];
    }
  }
  html {
    scroll-behavior: smooth;
  }

  #app {
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: max-content;
    background-color: black;
    box-sizing: border-box;
    overflow-x: clip;
    background-image: url('./assets/img/bg.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: scroll;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
</style>
